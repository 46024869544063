import React, {useEffect} from "react";
import {useToggle} from "usehooks-ts";
import Button from "../Button";

export type Props = {
  checked?: boolean;
  className?: string;
  color?: string;
  onChange: (isChecked: boolean) => void;
};

function Checkbox({
  className,
  onChange,
  color,
  checked = false,
}: Props): React.ReactElement {
  const [value, toggle, setValue] = useToggle(checked);
  useEffect(() => {
    setValue(checked);
  }, [checked]);
  return (
    <Button
      as='simple'
      className={`${
        className ??
        "bg-plain border  rounded-full relative border-zinc-200 w-6 h-6 "
      }`}
      onClick={() => {
        toggle();
        onChange(!checked);
      }}
    >
      <div
        className={`rounded-full  absolute inset-[3px] ${
          value ? "bg-primary-500 " : "bg-zinc-50"
        }`}
        style={{backgroundColor: value ? color : ""}}
      />
    </Button>
  );
}

export default Checkbox;

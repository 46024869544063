import React, {createContext, useContext, useState} from "react";

type UserContextType = {
  user: User | undefined;
  setUser: (newUser: User | undefined) => void;
};

const UserContext = createContext<UserContextType | undefined>(undefined);

function UserProvider({
  children,
  user,
}: {
  children: React.ReactElement;
  user: User | undefined;
}) {
  const [stateUser, setStateUser] = useState<User | undefined>(user);

  const value = React.useMemo(
    () => ({user: stateUser, setUser: setStateUser}),
    [stateUser]
  );

  return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
}

const useUser = () => {
  const context = useContext(UserContext);
  if (!context) {
    throw new Error("useUser must be used inside an UserProvider");
  }
  return context;
};

export {UserProvider, useUser};

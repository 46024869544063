import {useUser} from "contexts/UserContext";
import useIsAuthenticated from "hooks/useIsAuthenticated";
import {useNavigate} from "react-router-dom";

function AuthLayout({children}: LayoutProps) {
  const navigate = useNavigate();
  const {user} = useUser();
  useIsAuthenticated(
    user,
    () => {
      navigate("/");
    },
    null
  );

  return <div>{children}</div>;
}

export default AuthLayout;

/* eslint-disable no-bitwise */
/* eslint-disable no-nested-ternary */
import React, {useRef, useState} from "react";
import {motion, AnimatePresence} from "framer-motion";
import {useOnClickOutside} from "usehooks-ts";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChevronDown, faChevronUp} from "@fortawesome/free-solid-svg-icons";
import Checkbox from "../Checkbox";

export type Props<T> = {
  value: T | T[] | null;
  items: T[];
  style?: Object;
  animate?: boolean;
  multiple?: boolean;
  showSelectAll?: boolean;
  showSelectAllRenderer?: (items: T[]) => React.ReactElement;
  animationDuration?: number;
  optionsStyle?: Object;
  itemRenderer?: (item: T) => React.ReactElement;
  selectedItemRenderer?: (item: T | T[], allItems: T[]) => React.ReactElement;
  placeholderRenderer?:
    | (() => React.ReactElement)
    | React.ReactElement
    | string;
  onChange?: (item: T) => void;
  onChangeMultiple?: (item: T[]) => void;
};

function Select<T>({
  value,
  animate,
  animationDuration = 0.3,
  items,
  style,
  multiple = false,
  showSelectAll = false,
  showSelectAllRenderer = undefined,
  optionsStyle,
  itemRenderer = undefined,
  selectedItemRenderer = undefined,
  placeholderRenderer = undefined,
  onChange,
  onChangeMultiple,
}: Props<T>): React.ReactElement {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [selectedItems, setSelectedItems] = useState<T[]>(
    value ? (Array.isArray(value) ? value : [value]) : []
  );
  const ref = useRef(null);
  const valueRef = useRef<HTMLDivElement>(null);

  useOnClickOutside(ref, () => setIsOpen(false));

  const select = (selectedItem: T) => {
    if (multiple) {
      let newSelectedItems: T[] = selectedItems;
      const selectedItemValue = (selectedItem as any).value;
      const exists: boolean =
        (newSelectedItems &&
          !!newSelectedItems.find((x: any) => x.value === selectedItemValue)) ??
        false;
      if (exists) {
        newSelectedItems = newSelectedItems.filter(
          (x: any) => x.value !== selectedItemValue
        );
      } else {
        newSelectedItems.push(selectedItem);
      }
      if (onChangeMultiple) {
        onChangeMultiple(newSelectedItems);
      }
      setSelectedItems(newSelectedItems);
    } else {
      if (onChange) {
        onChange(selectedItem);
      }
      setSelectedItems([selectedItem]);
      setIsOpen(false);
    }
  };

  const computedSelectedItemRenderer = (itemValue: T | T[]) => {
    if (Array.isArray(itemValue)) {
      if (selectedItemRenderer) {
        return selectedItemRenderer(itemValue, items);
      }
      if (placeholderRenderer) {
        if (placeholderRenderer instanceof Function) {
          return placeholderRenderer();
        }
        return placeholderRenderer;
      }
      return (
        <div className='p-2 text-sm'>
          {(itemValue as unknown as {label: string}).label}
        </div>
      );
    }
    if (selectedItemRenderer) {
      return selectedItemRenderer(itemValue, items);
    }
    if (itemRenderer) {
      return itemRenderer(itemValue);
    }
    return (
      <div className='p-2 text-sm'>
        {(itemValue as unknown as {label: string}).label}
      </div>
    );
  };
  const computedItemRenderer = (itemValue: T, type: "selected" | "item") => {
    if (type === "selected" && selectedItemRenderer) {
      return selectedItemRenderer(itemValue, items);
    }
    if (itemRenderer) {
      return itemRenderer(itemValue);
    }
    return (
      <div className='p-2 text-sm'>
        {(itemValue as unknown as {label: string}).label}
      </div>
    );
  };

  const computedMaxHeight =
    optionsStyle && "maxHeight" in optionsStyle
      ? (optionsStyle?.maxHeight as number)
      : 300;

  const computedDuration = animate
    ? animationDuration ?? computedMaxHeight / 1000
    : 0;

  const valueDivHeight = valueRef?.current ? valueRef.current.offsetHeight : 0;
  return (
    <div
      className='relative bg-plain'
      style={{width: "100%", ...style}}
      ref={ref}
    >
      <div
        role='button'
        tabIndex={-1}
        ref={valueRef}
        className={`relative border border-plain ${
          isOpen ? "rounded-t  shadow-lg" : "rounded"
        }`}
        onClick={() => {
          setIsOpen((prevIsOpen) => !prevIsOpen);
        }}
      >
        <div className=''>
          <div className='mr-12'>
            {value ? (
              computedSelectedItemRenderer(value)
            ) : placeholderRenderer ? (
              placeholderRenderer instanceof Function ? (
                placeholderRenderer()
              ) : (
                placeholderRenderer
              )
            ) : (
              <div className='p-2 text-sm'>Selecciona...</div>
            )}
          </div>
          <div className='absolute top-[50%] right-3 mt-[-11px]'>
            <FontAwesomeIcon
              icon={isOpen ? faChevronUp : faChevronDown}
              className='text-plain'
            />
          </div>
        </div>
      </div>
      <AnimatePresence>
        {isOpen && (
          <motion.div
            key='options'
            initial={{maxHeight: 0}}
            exit={{maxHeight: 0}}
            animate={{
              maxHeight: computedMaxHeight,
            }}
            transition={{duration: computedDuration}}
            className='absolute z-10 shadow-lg border mt-[-1px] left-0 right-0 border-plain  bg-plain rounded-b'
            style={{
              top: valueDivHeight,
              overflowY: "auto",
              ...optionsStyle,
              maxHeight: computedMaxHeight,
            }}
          >
            {multiple &&
              showSelectAll &&
              items.length !== selectedItems?.length &&
              showSelectAllRenderer && (
                <div
                  key='select-all'
                  className='cursor-pointer border-b border-zinc-200'
                  role='button'
                  tabIndex={-1}
                  onClick={() => {
                    if (onChangeMultiple) {
                      onChangeMultiple(items);
                    }
                    setSelectedItems(items);
                    setIsOpen(false);
                  }}
                >
                  {showSelectAllRenderer(items)}
                </div>
              )}
            {items.map((singleItem: any, ix) => (
              <div
                // eslint-disable-next-line react/no-array-index-key
                key={ix}
                role='button'
                tabIndex={-1}
                onClick={() => {
                  select(singleItem);
                }}
                className={`hover:bg-zinc-100 hover:dark:bg-zinc-400 ${
                  multiple ? "flex pl-2 gap-2 items-center" : ""
                }`}
              >
                {multiple ? (
                  <Checkbox
                    color={singleItem.color}
                    checked={
                      !!selectedItems.find(
                        (x: any) => x.value === singleItem.value
                      )
                    }
                    onChange={() => {}}
                  />
                ) : null}
                {computedItemRenderer(singleItem, "item")}
              </div>
            ))}
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
}

export default Select;

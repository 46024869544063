"use client";

import * as React from "react";
import {Calendar as CalendarIcon} from "lucide-react";

import dayjs from "dayjs";
import {SelectSingleEventHandler} from "react-day-picker";
import {cn} from "@/lib/utils";
import {Button} from "@/components/ui/button";
import {Calendar} from "@/components/ui/calendar";
import {Popover, PopoverContent, PopoverTrigger} from "@/components/ui/popover";

type Props = {
  defaultValue?: Date | undefined;
  value?: Date | undefined;
  placeholder?: string;
  onChange: (newValue: Date | undefined) => void;
};
function DatePicker({
  defaultValue,
  value,
  placeholder = "Pick a date",
  onChange,
}: Props) {
  const [date, setDate] = React.useState<Date | undefined>(
    value || defaultValue
  );
  const [isPopoverOpen, setIsPopoverOpen] = React.useState(false);

  React.useEffect(() => {
    setDate(value);
  }, [value]);

  const handleOnSelect: SelectSingleEventHandler = (
    newDate: Date | undefined
  ) => {
    onChange(newDate);
    setDate(newDate);
    setIsPopoverOpen(false);
  };

  return (
    <Popover open={isPopoverOpen} onOpenChange={setIsPopoverOpen}>
      <PopoverTrigger asChild>
        <Button
          variant='outline'
          className={cn(
            "w-full justify-start text-left font-normal bg-plain border-plain",
            !date && "text-muted-foreground"
          )}
        >
          <CalendarIcon className='mr-2 h-4 w-4' />
          {date ? dayjs(date).format("YYYY-MM-DD") : <span>{placeholder}</span>}
        </Button>
      </PopoverTrigger>
      <PopoverContent className='w-auto p-0'>
        <Calendar
          mode='single'
          selected={date}
          onSelect={handleOnSelect}
          initialFocus
        />
      </PopoverContent>
    </Popover>
  );
}

export default DatePicker;

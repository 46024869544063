import apiClient from 'api';

export const client = {
  loginFromGoogle: async (token: string): Promise<ServiceResponse<AuthInfo>> => apiClient.retrieve<AuthInfo>({
    url: 'login/google/token',
    method: 'post',
    payload: {
      'access_token': token
    }
  }),

  me: async (): Promise<ServiceResponse<User>> => apiClient.retrieve<User>({
    url: '/user/me',
    method: 'get',
  }),

};

export default client;
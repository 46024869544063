import axios, { AxiosError, AxiosInstance, AxiosResponse } from 'axios';
import { clearToken, getToken } from 'helpers/storage';
import { API_BASE_URL } from 'environment';

const defaultConfig = {
  headers: { 'Content-Type': 'application/json' },
  baseURL: API_BASE_URL,
};


class ApiClient {
  instance: AxiosInstance;

  constructor(headerName = 'Bearer', addToken = true) {
    this.instance = axios.create(defaultConfig) as AxiosInstance;
    if (!this.instance) {
      return;
    }
    if (addToken) {
      this.instance.interceptors.request.use((config) => {
        const newConfig = {
          ...config,
        };
        const token = getToken();
        if (token) {
          newConfig.headers.Authorization = `${headerName} ${getToken()}`;
        }
        return newConfig;
      });
    }
    this.instance.interceptors.response.use(
      (response: AxiosResponse) => response,
      (error: AxiosError) => {
        if (error
          && error.response
          && error.response.status
          && error.response.status === 401
        ) {
          clearToken();
        }
        return Promise.reject(error);
      },
    );


  }
}

export default ApiClient

/* eslint-disable react/jsx-props-no-spreading */
import DatePicker from 'components/Datepicker';
import dayjs from 'dayjs';
import numeral from 'numeral';
import {Controller, SubmitHandler, useForm} from 'react-hook-form';
import {useNavigate} from 'react-router-dom';
import {motion, AnimatePresence} from 'framer-motion';

import movementsService from 'services/movements';

import categoriesService from 'services/categories';
import {useService} from 'services/hooks/useService';
import Select from 'components/UI/Select';
import {useEffect, useRef, useState} from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {
  faRotate,
  faSpinner,
  faTimes,
  faTimesCircle,
  faWandMagicSparkles,
} from '@fortawesome/free-solid-svg-icons';
import Button from 'components/UI/Button';
import Numpad from 'components/UI/Numpad';
import isMobile from 'is-mobile';
import MovementForm from 'modules/MovementForm';
import {useLocalStorage} from 'usehooks-ts';
import Input from 'components/UI/Input';

const quickMovements: CreateMovement[] = [
  {
    concept: 'Café',
    category_id: 2,
    amount: 2200,
  },
  {
    concept: 'Cigarros',
    category_id: 3,
    amount: 4600,
  },
  {
    concept: 'Bus',
    category_id: 4,
    amount: 800,
  },
];

function AddMovementModule() {
  const navigate = useNavigate();

  const [createMessage, setCreateMessage] = useState<string>('');
  const [addAnother, setAddAnother] = useState<boolean>(false);
  const [parsedMovements, setParsedMovements] = useState<ParsedMovement[]>();
  const [message, setMessage] = useState<Message | undefined>();
  const [saving, setSaving] = useState<boolean>();
  const [parsing, setParsing] = useState<boolean>();
  const [showType, setShowType] = useState<'simple' | 'custom'>('simple');
  const [showNumpad, setShowNumpad] = useState<boolean>(false);
  const {status: statusCategories, response: categories} = useService<
    Category[]
  >(async () => categoriesService.all(), true);

  const {status: statusMovements, response: concepts} = useService<
    UserConcept[]
  >(async () => movementsService.stats(), true);

  const [lastDate, setLastDate] = useLocalStorage<Date>(
    'lastDate',
    dayjs().startOf('day').toDate()
  );

  const formValues: CreateMovement = {
    concept: '',
    category_id: 0,
    amount: 0,
    date: lastDate || dayjs().startOf('day').toDate(),
  };
  const formValuesRef = useRef<CreateMovement>();
  formValuesRef.current = formValues;

  const {
    control,
    register,
    handleSubmit,
    setValue,
    reset,
    watch,
    formState: {isValid},
  } = useForm<CreateMovement>({
    defaultValues: formValuesRef.current,
  });

  const watchConcept = watch('concept');
  const watchAmount = watch('amount');

  const watchDate = watch('date');

  useEffect(() => {
    if (watchDate) {
      setLastDate(watchDate);
    }
  }, [watchDate]);
  const selectAndUse = (newMovement: ParsedMovement) => {
    setParsedMovements((prev) =>
      prev?.filter((x) => x.key !== newMovement.key)
    );
    setValue('amount', newMovement.amount, {
      shouldValidate: true,
      shouldTouch: true,
    });
    setValue('concept', newMovement.concept, {
      shouldValidate: true,
    });
    if (newMovement.category?.id) {
      setValue('category_id', newMovement.category.id, {
        shouldValidate: true,
      });
    }
    setValue('date', dayjs(newMovement.date).toDate(), {
      shouldValidate: true,
    });
  };
  const parse = async () => {
    setParsing(true);
    const rsp = await movementsService.parse(createMessage);
    if (rsp.status && rsp.data.status === 'ok') {
      setParsedMovements(rsp.data.movements);
    } else {
      setParsedMovements(undefined);
    }
    setParsing(false);
  };

  const addMovement = async (persistMovement: CreateMovement) => {
    setSaving(true);
    const rsp = await movementsService.add(persistMovement);
    if (rsp.status) {
      if (!addAnother) {
        navigate('/');
      } else {
        setMessage({
          type: 'success',
          message: 'Movement added successfully',
        });
        window.setTimeout(() => {
          setMessage(undefined);
        }, 2000);
        reset();
      }
    }
    setSaving(false);
  };

  const onSubmit: SubmitHandler<CreateMovement> = addMovement;

  if (statusCategories === 'executing' || statusMovements === 'executing') {
    return (
      <div className='text-zinc-500 text-center h-[80vh] grid justify-center items-center'>
        <div className='flex flex-col gap-2'>
          <FontAwesomeIcon icon={faRotate} spin />
          <div className='text-sm'>Loading...</div>
        </div>
      </div>
    );
  }

  const categoriesItems = (categories ?? []).map((category: Category) => ({
    label: category.name,
    value: category.id,
  }));

  if (saving) {
    return (
      <div className='text-zinc-500 text-center h-[80vh] grid justify-center items-center'>
        <div className='flex flex-col gap-2'>
          <FontAwesomeIcon icon={faRotate} spin />
          <div className='text-sm'>Saving...</div>
        </div>
      </div>
    );
  }

  if (showType === 'simple') {
    return (
      <div className='h-full inner'>
        <AnimatePresence>
          {showNumpad && (
            <div className='absolute bottom-[1px] inset-x-0 z-[1001] overflow-hidden'>
              <motion.div
                initial={{transform: 'translateY(100%)'}}
                animate={{transform: 'translateY(0%)'}}
                exit={{transform: 'translateY(100%)'}}
                transition={{duration: 0.25, type: 'tween'}}
                style={{maxWidth: 400, width: '100%', margin: '0 auto'}}
              >
                <Numpad
                  value={watchAmount}
                  onChange={(newValue) => setValue('amount', newValue)}
                  onClose={() => setShowNumpad(false)}
                />
              </motion.div>
            </div>
          )}
        </AnimatePresence>
        <AnimatePresence>
          {message && (
            <motion.div
              initial={{y: '100%', x: '-50%'}}
              animate={{y: 0, x: '-50%'}}
              exit={{opacity: 0}}
              transition={{duration: 0.25, type: 'tween'}}
              className={`absolute left-1/2 z-[1001] -translate-x-1/2 bottom-4 p-4 ${
                message.type === 'success' ? 'bg-green-50' : 'bg-red-50'
              }`}
            >
              {message.message}
            </motion.div>
          )}
        </AnimatePresence>
        <form className='h-full' onSubmit={handleSubmit(onSubmit)}>
          <div className='h-full grid grid-rows-[auto_auto_auto_auto_1fr_auto_auto_auto]'>
            <div className='border-b border-zinc-200 bg-zinc-50 p-2'>
              <div className='flex gap-2 items-center relative'>
                <Input
                  onChange={(v) => setCreateMessage(v)}
                  placeholder='Type to create...'
                  className='flex-grow py-2 px-2 pr-9'
                  onKeyDown={(e) => {
                    e.stopPropagation();
                    if (e.key === 'Enter') {
                      e.preventDefault();
                      parse();
                      e.currentTarget.blur();
                    }
                  }}
                  value={createMessage}
                />
                <Button
                  as='simple'
                  onClick={() => parse()}
                  className='absolute top-0 right-1 bottom-0 p-2 grid justify-center items-center'
                >
                  {parsing ? (
                    <FontAwesomeIcon icon={faSpinner} spin />
                  ) : (
                    <FontAwesomeIcon icon={faWandMagicSparkles} />
                  )}
                </Button>
              </div>
              {parsedMovements?.map((parsedMovement) => (
                <div
                  key={parsedMovement.key}
                  className='flex gap-2 items-start justify-between p-2 border-b border-zinc-200 last:border-0'
                >
                  <div className='flex gap-0 flex-col'>
                    <div>{parsedMovement.concept}</div>
                    <div className='text-xs'>
                      {parsedMovement.category?.name ?? ''}
                    </div>
                  </div>
                  <div>{numeral(parsedMovement.amount).format('$0,0')}</div>
                  <div>
                    <Button
                      onClick={() => {
                        selectAndUse(parsedMovement);
                      }}
                    >
                      Use
                    </Button>
                  </div>
                </div>
              ))}
            </div>
            <div className='grid grid-cols-3 md:grid-cols-6 gap-2 p-2 border-b border-zinc-200 bg-zinc-50'>
              {quickMovements.map((movement) => (
                <div
                  role='button'
                  tabIndex={-1}
                  className='text-sm border bg-white border-zinc-200 text-center text-zinc-500 p-2 rounded flex flex-col gap-2'
                  onClick={() => {
                    setValue('amount', movement.amount, {
                      shouldValidate: true,
                      shouldTouch: true,
                    });
                    setValue('concept', movement.concept, {
                      shouldValidate: true,
                    });
                    setValue('category_id', movement.category_id, {
                      shouldValidate: true,
                    });
                  }}
                >
                  <div className=''>{movement.concept}</div>
                  <div className='text-sm'>
                    {numeral(movement.amount).format('$0,0')}
                  </div>
                </div>
              ))}
            </div>
            <div className='p-4 grid grid-cols-[auto_1fr_auto] text-4xl  items-center  border-b border-zinc-200'>
              <div className=''>$</div>
              <input
                autoComplete='off'
                inputMode='none'
                type='number'
                readOnly={isMobile()}
                onFocus={() => {
                  if (isMobile()) {
                    setShowNumpad(true);
                  }
                }}
                className=' flex-grow bg-plain w-full  h-full outline-none'
                placeholder='...'
                {...register('amount', {required: true})}
              />
              <Button
                as='simple'
                onClick={() => setValue('amount', 0)}
                disabled={watchAmount === 0}
              >
                <FontAwesomeIcon
                  size='2x'
                  icon={faTimesCircle}
                  className={`${
                    watchAmount > 0 ? 'text-red-500' : 'text-zinc-400'
                  }`}
                />
              </Button>
            </div>
            <div className='overflow-y-auto h-full p-4 border-b border-zinc-200 bg-zinc-50'>
              <Controller
                name='concept'
                control={control}
                rules={{required: true}}
                render={({field}) => (
                  <div className='grid gap-2 grid-cols-2 sm:grid-cols-4'>
                    {concepts?.map((concept: UserConcept) => (
                      <div
                        role='button'
                        tabIndex={-1}
                        className={`rounded bg-white flex gap-2 justify-between items-center p-2 py-4 border text-center ${
                          watchConcept === concept.concept
                            ? 'bg-zinc-400 border-zinc-400 text-white font-bold'
                            : 'border-zinc-200'
                        }`}
                        onClick={() => {
                          setShowNumpad(false);
                          field.onChange(concept.concept);
                          if (concept.categories[0]?.id) {
                            setValue('category_id', concept.categories[0].id);
                          }
                        }}
                      >
                        <span className='text-xs overflow-hidden truncate text-ellipsis '>
                          {concept.concept}
                        </span>
                        <span className='text-xs rounded-full text-zinc-500 aspect-square bg-zinc-200 p-1 w-6 h-6 flex place-content-center '>
                          {concept.n}
                        </span>
                      </div>
                    ))}
                  </div>
                )}
              />
            </div>
            <div className='p-4 pb-0 grid grid-cols-2 gap-2'>
              <div className=''>
                <Controller
                  name='category_id'
                  control={control}
                  rules={{required: true}}
                  render={({field}) => (
                    <Select<SelectItem<number>>
                      items={categoriesItems}
                      placeholderRenderer={
                        <div className='p-2 text-sm'>Select Category...</div>
                      }
                      value={
                        categoriesItems.find((x) => x.value === field.value) ||
                        null
                      }
                      onChange={(v) => {
                        field.onChange(v.value);
                      }}
                    />
                  )}
                />
              </div>
              <div className=''>
                <Controller
                  name='date'
                  control={control}
                  rules={{required: true}}
                  render={({field}) => (
                    <>
                      <DatePicker
                        defaultValue={field.value}
                        placeholder='When...'
                        {...field}
                      />
                      <div className='text-xs flex gap-2 mt-1'>
                        <Button
                          as='link'
                          size='sm'
                          onClick={() =>
                            setValue(
                              'date',
                              dayjs()
                                .subtract(0, 'day')
                                .startOf('day')
                                .toDate(),
                              {
                                shouldDirty: true,
                                shouldValidate: true,
                                shouldTouch: true,
                              }
                            )
                          }
                        >
                          Today
                        </Button>
                        <Button
                          as='link'
                          size='sm'
                          onClick={() =>
                            setValue(
                              'date',
                              dayjs()
                                .subtract(1, 'day')
                                .startOf('day')
                                .toDate(),
                              {
                                shouldValidate: true,
                                shouldTouch: true,
                              }
                            )
                          }
                        >
                          Yesterday
                        </Button>
                      </div>
                    </>
                  )}
                />
              </div>
            </div>
            <div className='px-2 py-4 mt-4 text-center'>
              <div className='grid grid-cols-2 sm:grid-cols-2 gap-4'>
                <Button
                  buttonType='submit'
                  onClick={() => {
                    setAddAnother(false);
                  }}
                  disabled={!isValid}
                >
                  Save
                </Button>
                <Button
                  buttonType='submit'
                  onClick={() => {
                    setAddAnother(true);
                  }}
                  disabled={!isValid}
                >
                  Save and Add another
                </Button>
              </div>

              <div className='mt-6 text-xs mb-6'>
                or{' '}
                <Button as='link' onClick={() => setShowType('custom')}>
                  Create from Scratch
                </Button>
              </div>
            </div>
          </div>
        </form>
      </div>
    );
  }

  return (
    <div className='p-4 flex flex-col gap-10'>
      <div className='inner'>
        <div className='mb-2 flex justify-between items-center'>
          <div className='font-bold '>Custom movement</div>
          <Button
            as='link'
            className='!text-zinc-500'
            onClick={() => setShowType('simple')}
          >
            <FontAwesomeIcon icon={faTimes} />
          </Button>
        </div>
        <MovementForm
          categoriesItems={categoriesItems}
          defaultValues={watch()}
          onSaving={() => setSaving(true)}
          onSave={(isAddAnother) => {
            setSaving(false);
            setShowType('simple');
            if (!isAddAnother) {
              navigate('/');
            } else {
              setMessage({
                type: 'success',
                message: 'Movement added successfully',
              });
              window.setTimeout(() => {
                setMessage(undefined);
              }, 2000);
              reset();
            }
          }}
          onError={() => setSaving(false)}
        />
      </div>
    </div>
  );
}
export default AddMovementModule;

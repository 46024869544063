import {faList, faPlus, faUser} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {useUser} from "contexts/UserContext";
import useIsAuthenticated from "hooks/useIsAuthenticated";
import {Link, useNavigate} from "react-router-dom";

function DefaultLayout({children}: LayoutProps) {
  const navigate = useNavigate();
  const {user} = useUser();
  const isAuthenticated = useIsAuthenticated(user, null, () => {
    navigate("/login");
  });
  if (!user || !isAuthenticated) {
    return null;
  }
  return (
    <div className='h-[100dvh] grid grid-rows-[1fr_auto] bg-plain text-plain'>
      <div className='overflow-y-auto  w-full mx-auto'>{children}</div>
      <div className='text-sm text-center border-b bg-zinc-200 text-plain dark:bg-zinc-900'>
        <div className='inner text-center items-center flex gap-20 justify-between'>
          <div className=''>
            <Link to='/'>
              <div className='rounded-full  grid items-center justify-center  text-zinc-500 dark:text-zinc-400 p-4'>
                <FontAwesomeIcon icon={faList} size='1x' />
              </div>
            </Link>
          </div>
          <div className=' -mt-8 z-[1000]'>
            <Link to='/movement/add'>
              <div
                className='rounded-full grid items-center justify-center
               bg-zinc-500 w-16 h-16 p-2 text-white dark:text-zinc-400 border-white border-4 shadow-md'
              >
                <FontAwesomeIcon
                  icon={faPlus}
                  size='3x'
                  className='mt-[-1px]'
                />
              </div>
            </Link>
          </div>
          <div className=''>
            <Link to='/movement/add'>
              <div className='rounded-full  grid items-center justify-center  text-zinc-500 dark:text-zinc-400 p-4'>
                <FontAwesomeIcon icon={faUser} size='1x' />
              </div>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DefaultLayout;

import { useEffect, useState } from 'react';

type Status = 'nonRunning' | 'executing' | 'success' | 'error'

type Loader<T> = () => Promise<ServiceResponse<T>>
export type UseServiceReturn<T> = {
  status: 'nonRunning'
  loader: Loader<T>
  response: undefined;
  error: undefined;
} | {
  status: 'executing'
  loader: Loader<T>
  response: undefined;
  error: undefined;
} | {
  status: 'success'
  loader: Loader<T>
  response: T;
  error: undefined;
} | {
  status: 'error',
  loader: Loader<T>
  response: undefined;
  error: string;
}

export function useService<T>(loader: Loader<T>, executeOnStart: boolean = false): UseServiceReturn<T> {
  const [status, setStatus] = useState<Status>(executeOnStart ? 'nonRunning' : 'nonRunning');
  const [error, setError] = useState<string>();
  const [response, setResponse] = useState<T>();

  const doLoad = async () => {
    setStatus('executing');
    const rsp = await loader();
    setResponse(rsp.status ? rsp.data : undefined);
    setError(rsp.status ? undefined : rsp.error);
    setStatus(rsp.status ? 'success' : 'error');
    return rsp;
  }
  useEffect(() => {
    if (executeOnStart) {
      doLoad()
    }
  }, [executeOnStart]);
  switch (status) {
    case 'nonRunning':
      return {
        status,
        loader: doLoad,
        response: undefined,
        error: undefined
      }
    case 'executing':
      return {
        status,
        loader: doLoad,
        response: undefined,
        error: undefined
      }
    case 'success':
      return {
        status,
        loader: doLoad,
        response: response as T,
        error: undefined,
      }
    default:
      return {
        status,
        loader: doLoad,
        response: undefined,
        error: error as string,
      }
  }

};

import React, {KeyboardEventHandler} from "react";

export type Props = {
  label?: string;
  value?: string;
  type?: string;
  className?: string;
  min?: string;
  placeholder?: string;
  onChange: (value: string) => void;
  onKeyDown?: KeyboardEventHandler<HTMLInputElement> | undefined;
};

function Input({
  label,
  value,
  type,
  className,
  min,
  placeholder,
  onChange,
  onKeyDown,
}: Props): React.ReactElement {
  if (!label) {
    return (
      <input
        type={type}
        className={`${className} bg-plain border border-zinc-200 text-sm rounded-lg  block w-full p-2 focus:outline-none`}
        placeholder={placeholder}
        value={value}
        onChange={(e) => onChange(e.target.value)}
        onKeyDown={onKeyDown}
        min={min ?? undefined}
      />
    );
  }

  return (
    <div className='flex flex-row justify-between gap-2'>
      <span className='text-sm'>{label}</span>
      <input
        type={type}
        className={`${className} grow bg-plain border border-zinc-200 text-sm rounded-lg  block w-full p-2 focus:outline-none`}
        placeholder={placeholder}
        value={value}
        onChange={(e) => onChange(e.target.value)}
        onKeyDown={onKeyDown}
        min={min ?? undefined}
      />
    </div>
  );
}

export default Input;

import {faChevronDown, faDeleteLeft} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import React, {useEffect, useState} from "react";
import Button from "../Button";

export type Props = {
  value?: number;
  onChange: (newValue: number) => void;
  onClose?: () => void;
};

function Numpad({value, onChange, onClose}: Props): React.ReactElement {
  const [stValue, setStValue] = useState(value ? value.toString() : "");

  useEffect(() => {
    setStValue(value ? value.toString() : "");
  }, [value]);
  useEffect(() => {
    onChange(stValue ? parseInt(stValue, 10) : 0);
  }, [stValue]);
  const handleKey = (key: string) => {
    if (key === "") {
      return;
    }
    setStValue((prevValue) => {
      if (key === "back") {
        return prevValue.slice(0, -1);
      }
      return prevValue + key;
    });
  };

  const keys = ["1", "2", "3", "4", "5", "6", "7", "8", "9", "", "0", "back"];
  return (
    <div className='rounded-t shadow-sm border border-zinc-200 bg-zinc-100'>
      <Button
        as='simple'
        className='w-full flex justify-end p-4 text-2xl rounded-t'
        onClick={() => {
          if (onClose) {
            onClose();
          }
        }}
      >
        <FontAwesomeIcon icon={faChevronDown} />
      </Button>
      <div className='grid grid-cols-3 gap-4 py-8 '>
        {keys.map((key) => (
          <div className=' flex items-center justify-center '>
            {!!key && (
              <div
                className='rounded-full w-auto h-full text-center aspect-square text-white bg-zinc-700  text-3xl font-semibold p-4 hover:outline-none'
                onClick={() => handleKey(key)}
                tabIndex={-1}
                role='button'
              >
                {key === "back" ? <FontAwesomeIcon icon={faDeleteLeft} /> : key}
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
}

export default Numpad;

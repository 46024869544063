import React from "react";
import DefaultLayout from "layouts/DefaultLayout";
import "./globals.css";
import {GoogleOAuthProvider} from "@react-oauth/google";
import {useService} from "services/hooks/useService";
import usersService from "services/users";
import {RouterProvider, createBrowserRouter} from "react-router-dom";
import {UserProvider} from "contexts/UserContext";
import LoginModule from "modules/LoginModule";
import AuthLayout from "layouts/AuthLayout";
import HomeModule from "modules/HomeModule";
import AddMovementModule from "modules/AddMovementModule";
import {GOOGLE_CLIENT_ID} from "environment";
import numeral from "numeral";

import "numeral/locales/es";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faRotate} from "@fortawesome/free-solid-svg-icons";

numeral.locale("es");

function App() {
  const {
    status,
    response: userResponse,
    error: userError,
  } = useService<User>(async () => usersService.me(), true);

  if (status === "executing") {
    return (
      <div className='text-zinc-500 text-center h-[80vh] grid justify-center items-center'>
        <div className='flex flex-col gap-2'>
          <FontAwesomeIcon icon={faRotate} spin />
          <div className='text-sm'>Loading...</div>
        </div>
      </div>
    );
  }

  type Path = {
    path: string;
    element: React.ReactElement;
    layout?: React.ReactElement;
  };
  const paths: Path[] = [
    {
      path: "/",
      element: (
        <DefaultLayout>
          <HomeModule />
        </DefaultLayout>
      ),
    },
    {
      path: "/movement/add",
      element: (
        <DefaultLayout>
          <AddMovementModule />
        </DefaultLayout>
      ),
    },
    {
      path: "/login",
      element: (
        <AuthLayout>
          <LoginModule />
        </AuthLayout>
      ),
    },
  ];

  const router = createBrowserRouter(
    paths.map((path) => ({
      path: path.path,
      element: path.element,
    }))
  );
  return (
    <GoogleOAuthProvider clientId={GOOGLE_CLIENT_ID}>
      <UserProvider user={userError ? undefined : userResponse}>
        <RouterProvider router={router} />
      </UserProvider>
    </GoogleOAuthProvider>
  );
}

export default App;

import { AxiosError } from 'axios';
import ApiClient from './client';


const apiClient = new ApiClient();

function returnSuccess<TData>(data: unknown): ServiceResponse<TData> {
  return {
    status: true,
    data: data as TData
  }
}

function returnError<TData>(error: string = ''): ServiceResponse<TData> {
  return {
    status: false,
    error
  }
}
async function retrieve<T>({
  url,
  method,
  payload,
  params
}: RetrieveProp) {
  try {
    const rsp = await apiClient.instance({
      url,
      method,
      data: payload,
      params,
    })
    if (rsp?.data) {
      return returnSuccess<T>(rsp.data);
    }
    return returnError<T>("Unknown error");

  } catch (e: unknown) {
    if (
      (e instanceof AxiosError) ||
      (e !== null && typeof e === 'object' && 'message' in e)
    ) {
      return returnError<T>(String(e.message))
    }
    return returnError<T>("Unknown error")
  }
}

export default {
  retrieve,
}



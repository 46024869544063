/* eslint-disable no-nested-ternary */

"use client";

import * as React from "react";
import {Calendar as CalendarIcon} from "lucide-react";
import {DateRange} from "react-day-picker";

import dayjs from "dayjs";
import Button from "components/UI/Button";
import {cn} from "@/lib/utils";
import {Button as UIButton} from "@/components/ui/button";
import {Calendar} from "@/components/ui/calendar";
import {Popover, PopoverContent, PopoverTrigger} from "@/components/ui/popover";

export type NamedRange = {
  key: string;
  label: string;
  range: [Date, Date];
};
type Props = {
  className?: string;
  range?: [Date, Date];
  predefinedRanges: NamedRange[];
  onChange: (value: [Date, Date]) => void;
};

const defaultRange = {
  from: dayjs(new Date()).subtract(30, "day").startOf("day").toDate(),
  to: new Date(),
};
function DatePickerWithRange({
  className,
  range,
  predefinedRanges,
  onChange,
}: Props) {
  const [date, setDate] = React.useState<DateRange>(
    range
      ? {
          from: range[0],
          to: range[1],
        }
      : defaultRange
  );

  React.useEffect(() => {
    setDate(
      range
        ? {
            from: range[0],
            to: range[1],
          }
        : defaultRange
    );
  }, [range]);

  return (
    <div className={cn("grid gap-2", className)}>
      <Popover>
        <PopoverTrigger asChild>
          <UIButton
            id='date'
            variant='outline'
            className={cn(
              "w-[300px] justify-start text-left font-normal",
              !date && "text-muted-foreground"
            )}
          >
            <CalendarIcon className='mr-2 h-4 w-4' />
            {date?.from ? (
              date.to ? (
                <>
                  {dayjs(date.from).format("YYYY-MM-DD")} -{" "}
                  {dayjs(date.to).format("YYYY-MM-DD")}
                </>
              ) : (
                dayjs(date.from).format("YYYY-MM-DD")
              )
            ) : (
              <span>Pick a date</span>
            )}
          </UIButton>
        </PopoverTrigger>
        <PopoverContent className='w-auto p-0' align='start'>
          <div className='flex gap-2 border border-slate-200 p-0'>
            <div className='flex flex-col border-r border-slate-200'>
              {predefinedRanges.map((x) => (
                <Button
                  as='simple'
                  className='p-3 border-b border-slate-200'
                  onClick={() => setDate({from: x.range[0], to: x.range[1]})}
                >
                  {x.label}
                </Button>
              ))}
            </div>
            <div className='flex-grow'>
              <Calendar
                initialFocus
                mode='range'
                defaultMonth={date?.from}
                selected={date}
                onSelect={(v) => {
                  const newValue = v ?? defaultRange;
                  setDate(newValue);
                  if (newValue.from && newValue.to) {
                    onChange([newValue.from, newValue.to]);
                  }
                }}
                numberOfMonths={2}
              />
            </div>
          </div>
        </PopoverContent>
      </Popover>
    </div>
  );
}

export default DatePickerWithRange;

import "./index.css";
import React, {MouseEventHandler} from "react";
import {cva, type VariantProps} from "class-variance-authority";

const buttonClasses = cva("", {
  variants: {
    type: {
      main: ["main"],
      secondary: ["sec"],
      danger: ["danger"],
    },
    size: {
      sm: ["sm"],
      md: ["md"],
      lg: ["lg"],
    },
    as: {
      button: ["as-button"],
      link: ["as-link"],
      simple: ["as-simple"],
    },
  },
  defaultVariants: {
    type: "main",
    size: "md",
    as: "button",
  },
});

type Props = {
  pressed?: boolean;
  noborder?: boolean;
  className?: string;
  block?: boolean;
  buttonType?: "button" | "submit";
  disabled?: boolean;
  as?: "button" | "link" | "simple";
  children: string | React.ReactNode;
  onClick?: MouseEventHandler<HTMLButtonElement>;
  onMouseEnter?: MouseEventHandler<HTMLButtonElement>;
  onMouseLeave?: MouseEventHandler<HTMLButtonElement>;
} & VariantProps<typeof buttonClasses>;

function Button({
  className = "",
  pressed = false,
  noborder = false,
  disabled = false,
  type = "main",
  buttonType = "button",
  block = false,
  size = "md",
  children,
  as = "button",
  onClick = () => {},
  onMouseEnter = () => {},
  onMouseLeave = () => {},
}: Props) {
  return (
    <button
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      type={buttonType === "button" ? "button" : "submit"}
      className={`button ${buttonClasses({type, size, as})} ${
        noborder ? "border-0" : ""
      } ${disabled ? "disabled" : ""} ${className} ${
        block ? "block w-full" : ""
      } ${pressed ? "bg-primary-500" : ""}`}
      onClick={(e) => {
        if (!disabled) {
          onClick(e);
        }
      }}
    >
      {children}
    </button>
  );
}

export default Button;

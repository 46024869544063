export const setToken = (token: string) => {
  if (token) {
    localStorage.setItem('token', token);
  }
};

export const clearToken = () => {
  localStorage.removeItem('token')
}

export const getToken = () => localStorage.getItem('token');

export const clearLocalStorage = () => {
  localStorage.clear();
};

/* eslint-disable react/no-array-index-key */
/* eslint-disable react/jsx-props-no-spreading */
import {
  faBell,
  faFilter,
  faRotate,
  faTimes,
} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import dayjs from 'dayjs';
import numeral from 'numeral';
import {useService} from 'services/hooks/useService';
import movementsService from 'services/movements';
import categoriesService from 'services/categories';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import isoWeek from 'dayjs/plugin/isoWeek';

import {
  ArcElement,
  PointElement,
  Tooltip,
  Legend,
  LineElement,
  BarElement,
  CategoryScale,
  LinearScale,
} from 'chart.js';

import ChartJS from 'chart.js/auto';

import {Chart, Doughnut} from 'react-chartjs-2';
import {avgLast} from 'helpers/data';
import Select from 'components/UI/Select';
import {useEffect, useState} from 'react';
import Button from 'components/UI/Button';
import MovementForm from 'modules/MovementForm';
import PullToRefresh from 'react-simple-pull-to-refresh';
import DatePickerWithRange, {NamedRange} from 'components/DateRangePicker';
import {Tabs, TabsContent, TabsList, TabsTrigger} from '@/components/ui/tabs';

dayjs.extend(isoWeek);
dayjs.extend(advancedFormat);

ChartJS.register(
  ArcElement,
  BarElement,
  PointElement,
  LineElement,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale
);

const colors = [
  '#c80815',
  '#035096',
  '#228b22',
  '#ffa700',
  '#9966ff',
  '#ff6600',
  '#ff0099',
  '#888888',
];

const itemsSelectdayGroupBy = [
  {
    value: 'day',
    label: 'Day',
  },
  {
    value: 'week',
    label: 'Week',
  },
];

const predefinedRanges: NamedRange[] = [
  {
    key: 'current_week',
    label: 'Current Week',
    range: [dayjs().startOf('week').toDate(), dayjs().endOf('day').toDate()],
  },
  {
    key: 'previous_week',
    label: 'Previous Week',
    range: [
      dayjs().startOf('week').subtract(1, 'day').startOf('week').toDate(),
      dayjs().startOf('week').subtract(1, 'day').endOf('day').toDate(),
    ],
  },
  {
    key: 'current_month',
    label: 'Current Month',
    range: [dayjs().startOf('month').toDate(), dayjs().endOf('day').toDate()],
  },

  {
    key: 'previous_month',
    label: 'Previous Month',
    range: [
      dayjs().startOf('month').subtract(1, 'day').startOf('month').toDate(),
      dayjs().startOf('month').subtract(1, 'day').endOf('day').toDate(),
    ],
  },
];
function HomeModule() {
  const [showEditMovement, setShowEditMovement] = useState<Movement>();
  const [range, setRange] = useState<[Date, Date]>();
  const [filterConceptByCategory, setFilterConceptByCategory] =
    useState<string>();
  const [dayGroupBy, setDayGroupBy] = useState<SelectItem<string>>(
    itemsSelectdayGroupBy[0]
  );

  useEffect(() => {
    const container = document.getElementById('tab-container');
    if (container !== null) {
      container.scroll({top: 0, behavior: 'smooth'});
    }
  }, [filterConceptByCategory]);

  const {
    response: movementsStats,
    status: statusMovements,
    loader: loadMovements,
  } = useService<MovementStats>(
    async () => movementsService.filter({range}),
    true
  );

  useEffect(() => {
    loadMovements();
  }, [JSON.stringify(range)]);

  const {response: allCategories} = useService<Category[]>(
    async () => categoriesService.all(),
    true
  );

  const categoriesItems = (allCategories ?? []).map((category: Category) => ({
    label: category.name,
    value: category.id,
  }));

  if (statusMovements === 'executing') {
    return (
      <div className='text-zinc-500 text-center h-[80vh] grid justify-center items-center'>
        <div className='flex flex-col gap-2'>
          <FontAwesomeIcon icon={faRotate} spin />
          <div className='text-sm'>Loading...</div>
        </div>
      </div>
    );
  }

  const movements = movementsStats?.movements;
  const categories = movementsStats?.categories;
  let concepts = movementsStats?.concepts;

  if (filterConceptByCategory) {
    concepts = concepts?.filter(
      (c) => c.category.name === filterConceptByCategory
    );
  }
  const days = movementsStats?.days ?? [];
  const nDays = days?.length ?? 1;
  const total = movements?.reduce((a, x) => a + x.amount, 0) ?? 0;

  const movementsByDay: Record<string, Movement[]> =
    movements?.reduce((acc, movement) => {
      const day = movement.date
        ? dayjs(movement.date).format('YYYY-MM-DD')
        : null;
      if (!day) {
        return acc;
      }
      if (!(day in acc)) {
        acc[day] = [];
      }
      acc[day].push(movement);
      return acc;
    }, {} as Record<string, Movement[]>) || {};

  let barDays = null;

  if (dayGroupBy.value === 'day') {
    barDays = [...days].reverse();
  } else if (dayGroupBy.value === 'week') {
    const byWeek = [...days].reverse().reduce((acc, day) => {
      const newAcc = acc;
      const week = dayjs(day.day).format('GGGG-WW');
      if (!(week in acc)) {
        acc[week] = {
          ...day,
          day: week,
        };
      } else {
        acc[week].total += day.total;
      }
      return newAcc;
    }, {} as Record<string, DayStats>);
    barDays = Object.values(byWeek);
  }

  const avgL7DTotal =
    days.slice(1, 8).reduce((a, x) => a + x.total, 0) / days.slice(1, 8).length;

  const pctMonthDays =
    Math.max(0, dayjs().get('date') - 1) / dayjs().daysInMonth();

  const barDaysWithAvg: DayStatsAvg[] = avgLast(
    barDays,
    7,
    'total',
    'avgTotalL7D'
  );

  const dailyBudget = 15000;

  const deltaVsBudget = total / nDays / dailyBudget - 1;
  const deltaVsBudgetL7D = avgL7DTotal / dailyBudget - 1;

  return (
    <PullToRefresh
      onRefresh={loadMovements}
      refreshingContent={
        <div className='p-8 text-center'>
          <FontAwesomeIcon icon={faRotate} spin />
        </div>
      }
    >
      <div className='h-full grid grid-rows-[auto_1fr]'>
        <div className='p-0 hidden '>
          <div className='inner'>
            <DatePickerWithRange
              range={range}
              predefinedRanges={predefinedRanges}
              onChange={(v) => setRange(v)}
            />
          </div>
        </div>
        <div className='p-0'>
          <div className='bg-zinc-100 shadow'>
            <div className='inner flex flex-col gap-2 p-4'>
              <div className='text-sm'>Total Spent</div>
              <div className='font-bold text-2xl mt-2'>
                {numeral(total).format('$0,0')}
              </div>
            </div>
            <div className='bg-zinc-50'>
              <div className='inner flex justify-between p-2'>
                <div className='text-sm mt-1 flex flex-col gap-2'>
                  <div>{numeral(total / nDays).format('$0,0')} per day</div>
                  <div
                    className={`text-xs ${
                      deltaVsBudget > 0 ? 'text-red-600' : ''
                    }`}
                  >
                    {deltaVsBudget > 0 && (
                      <FontAwesomeIcon icon={faBell} className='mr-1' />
                    )}
                    {numeral(deltaVsBudget).format('+0%')} vs budget
                  </div>
                </div>
                <div className='text-sm mt-1 text-right  flex flex-col gap-2'>
                  <div>{numeral(avgL7DTotal).format('$0,0')} per day L7D</div>
                  <div
                    className={`text-xs ${
                      deltaVsBudgetL7D > 0 ? 'text-red-600' : ''
                    }`}
                  >
                    {deltaVsBudgetL7D > 0 && (
                      <FontAwesomeIcon icon={faBell} className='mr-1' />
                    )}
                    {numeral(deltaVsBudgetL7D).format('+0%')} vs budget
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className='relative inner h-full  px-0 pt-4 overflow-hidden'>
          <Tabs
            defaultValue='byDay'
            className='h-full grid grid-rows-[auto_1fr]'
            onValueChange={() => {
              const container = document.getElementById('tab-container');
              if (container !== null) {
                container.scroll({top: 0, behavior: 'smooth'});
              }
            }}
          >
            <TabsList>
              <TabsTrigger value='byDay'>By Day</TabsTrigger>
              <TabsTrigger value='byCategory'>By Category</TabsTrigger>
              <TabsTrigger value='byConcept'>By Concept</TabsTrigger>
              <TabsTrigger value='details'>Details</TabsTrigger>
            </TabsList>

            <div className='mt-4 h-full overflow-y-auto' id='tab-container'>
              <TabsContent value='byCategory'>
                <div className='grid grid-cols-1 sm:grid-cols-[1fr_auto] gap-8'>
                  <table className='w-full text-sm'>
                    <thead>
                      <tr className='border-b border-zinc-500 text-zinc-500'>
                        <th className='p-1 text-left'>Category</th>
                        <th className='p-1 text-center'>Movements</th>
                        <th className='p-1 text-right'>Amount</th>
                        <th className='p-1 text-right'>%</th>
                        <th className='p-1 text-right'>Budget MTD/Total</th>
                      </tr>
                    </thead>
                    <tbody>
                      {categories?.map((category: CategoryStats, i: number) => (
                        <tr
                          key={i}
                          className='border-b border-zinc-200 dark:border-zinc-700 last:border-0'
                        >
                          <td className='p-1'>
                            <div className='flex gap-2 items-start'>
                              <div
                                className='w-4 h-4 rounded'
                                style={{
                                  backgroundColor: colors[i % colors.length],
                                }}
                              />
                              <div className='flex flex-col'>
                                <div className='text-xs'>
                                  {category.category?.group}
                                </div>
                                <div>{category.category.name}</div>
                              </div>
                            </div>
                          </td>
                          <td className='p-1 text-center'>
                            {numeral(category.movements).format('0,0')}
                          </td>
                          <td className='p-1 text-right'>
                            {numeral(category.total).format('$0,0')}
                          </td>{' '}
                          <td className='p-1 text-right'>
                            {numeral(category.total / total).format('0%')}
                          </td>
                          <td
                            className={`p-2 text-right ${
                              category.total >
                              category.category.budget * pctMonthDays
                                ? 'text-red-500'
                                : 'text-green-500'
                            }`}
                          >
                            <div className='flex flex-col gap-1'>
                              <div
                                className={`${
                                  category.total >
                                  category.category.budget * pctMonthDays
                                    ? 'text-red-500'
                                    : 'text-green-500'
                                }`}
                              >
                                {numeral(
                                  category.category.budget * pctMonthDays
                                ).format('$0,0')}
                              </div>
                              <div
                                className={`text-xxs ${
                                  category.total > category.category.budget
                                    ? 'text-red-500'
                                    : 'text-green-500'
                                }`}
                              >
                                {numeral(category.category.budget).format(
                                  '$0,0'
                                )}
                              </div>
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                  <div className='p-2'>
                    <Doughnut
                      width={150}
                      data={{
                        labels: categories?.map(
                          (category: CategoryStats) => category.category.name
                        ),
                        datasets: [
                          {
                            data: categories?.map(
                              (category: CategoryStats) =>
                                category.total / total
                            ),
                            backgroundColor: categories?.map(
                              (category: CategoryStats, ix) =>
                                `${colors[ix % colors.length]}ff`
                            ),
                            borderColor: '#ffffff44',
                          },
                        ],
                      }}
                      options={{
                        plugins: {
                          legend: {display: false},
                          tooltip: {
                            callbacks: {
                              label(context) {
                                let label = '';

                                const {parsed, dataIndex} = context;

                                const category = categories?.[dataIndex];
                                label += `${numeral(category?.total).format(
                                  '$0,0'
                                )} (${numeral(parsed).format('0%')})`;
                                return label;
                              },
                            },
                          },
                        },
                      }}
                    />
                  </div>
                </div>
              </TabsContent>
              <TabsContent value='byConcept'>
                <div className='grid grid-cols-1 sm:grid-cols-[1fr_auto] gap-8'>
                  <div>
                    {filterConceptByCategory && (
                      <div className='mb-4 text-xs bg-yellow-50 p-2 pb-1 rounded flex gap-2 items-start'>
                        <div className='flex gap-1 items-start'>
                          <FontAwesomeIcon icon={faFilter} />
                          <span>
                            Filtered by category: {filterConceptByCategory}
                          </span>
                        </div>
                        <button
                          type='button'
                          className='inline mt-[1px]'
                          onClick={() => setFilterConceptByCategory(undefined)}
                        >
                          <FontAwesomeIcon icon={faTimes} />
                        </button>
                      </div>
                    )}
                    <div className='mt-4 py-4  px-4 border-t border-zinc-200 flex justify-between'>
                      <div className='flex flex-col gap-2'>
                        <div className='text-xs'>Total</div>
                        <div>
                          {numeral(
                            concepts?.reduce((acc, x) => x.total + acc, 0) ?? 0
                          ).format('$0,0')}
                        </div>
                      </div>
                      <div className='flex flex-col gap-2'>
                        <div className='text-xs'>Movements</div>
                        <div>
                          {concepts?.reduce((acc, x) => x.movements + acc, 0) ??
                            0}
                        </div>
                      </div>
                      <div className='flex flex-col gap-2'>
                        <div className='text-xs'>Concepts</div>
                        <div>{concepts?.length ?? 0}</div>
                      </div>
                    </div>
                    <table className='w-full text-sm'>
                      <thead>
                        <tr className='border-b border-zinc-500 text-zinc-500'>
                          <th className='p-1 text-left'>Concept</th>
                          <th className='p-1 text-center'>Movements</th>
                          <th className='p-1 text-right'>Amount</th>
                          <th className='p-1 text-right'>%</th>
                        </tr>
                      </thead>
                      <tbody>
                        {concepts?.map((concept: ConceptStats, i: number) => (
                          <tr
                            key={i}
                            className='border-b border-zinc-200 dark:border-zinc-700 last:border-0'
                          >
                            <td className='p-1'>
                              <div className='flex gap-2 items-start'>
                                <div
                                  className='w-4 h-4 aspect-square rounded'
                                  style={{
                                    backgroundColor: colors[i % colors.length],
                                  }}
                                />
                                <div>
                                  <div className='flex gap-1 items-center text-xs'>
                                    <div>{concept.category?.group}</div>
                                    {' > '}
                                    <button
                                      type='button'
                                      className='underline'
                                      onClick={() =>
                                        setFilterConceptByCategory(
                                          filterConceptByCategory !==
                                            concept.category.name
                                            ? concept.category.name
                                            : undefined
                                        )
                                      }
                                    >
                                      {concept.category.name}
                                    </button>{' '}
                                  </div>
                                  <div>{concept.concept}</div>
                                </div>
                              </div>
                            </td>
                            <td className='p-1 text-center'>
                              {numeral(concept.movements).format('0,0')}
                            </td>
                            <td className='p-1 text-right'>
                              {numeral(concept.total).format('$0,0')}
                            </td>{' '}
                            <td className='p-1 text-right'>
                              {numeral(concept.total / total).format('0%')}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                  <div className='p-2'>
                    <Doughnut
                      width={150}
                      data={{
                        labels: concepts?.map(
                          (concept: ConceptStats) =>
                            `${concept.category.name} - ${concept.concept}`
                        ),
                        datasets: [
                          {
                            data: concepts?.map(
                              (concept: ConceptStats) => concept.total / total
                            ),
                            backgroundColor: concepts?.map(
                              (_, ix) => `${colors[ix % colors.length]}ff`
                            ),
                            borderColor: '#ffffff44',
                          },
                        ],
                      }}
                      options={{
                        plugins: {
                          legend: {display: false},
                          tooltip: {
                            callbacks: {
                              label(context) {
                                let label = '';

                                const {parsed, dataIndex} = context;

                                const concept = concepts?.[dataIndex];
                                label += `${numeral(concept?.total).format(
                                  '$0,0'
                                )} (${numeral(parsed).format('0%')})`;
                                return label;
                              },
                            },
                          },
                        },
                      }}
                    />
                  </div>
                </div>
              </TabsContent>
              <TabsContent value='byDay'>
                <div className='grid grid-cols-1 gap-8'>
                  <div className='flex flex-col gap-4'>
                    <div className='grid grid-cols-[80px_1fr] items-center gap-2'>
                      <span className='text-xs'>Group by:</span>
                      <Select
                        items={itemsSelectdayGroupBy}
                        value={dayGroupBy}
                        onChange={(value) => setDayGroupBy(value)}
                      />
                    </div>
                    <Chart
                      type='bar'
                      height={120}
                      data={{
                        labels: barDaysWithAvg.map((day) => day.day),
                        datasets: [
                          {
                            data: barDaysWithAvg.map((day) => day.total),
                            backgroundColor: barDaysWithAvg.map((day) =>
                              // eslint-disable-next-line no-nested-ternary
                              day.total <= dailyBudget
                                ? colors[2]
                                : day.total <= dailyBudget * 1.5
                                ? colors[3]
                                : colors[0]
                            ),
                            borderWidth: 1,
                          },
                          {
                            data: barDaysWithAvg.map((day) => day.avgTotalL7D),
                            borderColor: `${colors[3]}`,
                            backgroundColor: `${colors[3]}`,
                            borderWidth: 1,
                            type: 'line',
                          },
                          {
                            data: barDaysWithAvg.map(() => dailyBudget),
                            borderColor: `${colors[2]}`,
                            backgroundColor: `${colors[2]}`,
                            borderWidth: 1,
                            pointRadius: 0,
                            type: 'line',
                          },
                          {
                            data: barDaysWithAvg.map(() => dailyBudget * 1.5),
                            borderColor: `${colors[4]}`,
                            backgroundColor: `${colors[4]}`,
                            borderWidth: 1,
                            pointRadius: 0,
                            type: 'line',
                          },
                        ],
                      }}
                      options={{
                        plugins: {
                          legend: {display: false},
                          tooltip: {
                            callbacks: {
                              label(context) {
                                let label: string[] = [];

                                const {parsed, dataIndex} = context;

                                const day = barDaysWithAvg?.[dataIndex];
                                label = label.concat([
                                  `${numeral(day?.total).format(
                                    '$0,0'
                                  )} (${numeral(parsed).format('0%')})`,
                                  `Avg L7D: ${numeral(day?.avgTotalL7D).format(
                                    '$0,0'
                                  )}`,
                                  '',
                                  ...Object.keys(day.groups).map(
                                    (group: string) =>
                                      `${group} - ${numeral(
                                        day.groups[group].total
                                      ).format('$0,0')}`
                                  ),
                                ]);
                                return label;
                              },
                            },
                          },
                        },
                      }}
                    />
                  </div>

                  <table className='w-full text-sm'>
                    <thead>
                      <tr className='border-b border-zinc-500 text-zinc-500'>
                        <th className='p-1 text-left'>Day</th>
                        <th className='p-1 text-center'>Movements</th>
                        <th className='p-1 text-right'>Amount</th>
                        <th className='p-1 text-right'>%</th>
                      </tr>
                    </thead>
                    <tbody>
                      {days?.map((day, i: number) => (
                        <tr
                          key={i}
                          className='border-b border-zinc-200 dark:border-zinc-700 last:border-0'
                        >
                          <td className='p-1'>{day.day}</td>
                          <td className='p-1 text-center'>
                            {numeral(day.movements).format('0,0')}
                          </td>
                          <td className='p-1 text-right'>
                            {numeral(day.total).format('$0,0')}
                          </td>
                          <td className='p-1 text-right'>
                            {numeral(day.total / total).format('0%')}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </TabsContent>
              <TabsContent value='details'>
                <div className='flex flex-col gap-4'>
                  {Object.keys(movementsByDay).map((day, i: number) => {
                    const movementsInDay = movementsByDay[day];
                    return (
                      <div
                        key={i}
                        className='border-b border-zinc-200 dark:border-zinc-700  px-2 py-4 last:border-0 flex gap-4 flex-col'
                      >
                        <div className='font-bold flex justify-between'>
                          <div>{dayjs(day).format('DD/MM')}</div>
                          <div className='text-right'>
                            {numeral(
                              movementsInDay.reduce((a, c) => a + c.amount, 0)
                            ).format('$0,0')}
                          </div>
                        </div>
                        <div className='flex flex-col gap-2'>
                          {movementsInDay?.map((movement: Movement) => (
                            <div className='flex justify-between text-xs'>
                              <div>
                                <div>
                                  <Button
                                    as='link'
                                    onClick={() =>
                                      setShowEditMovement(movement)
                                    }
                                  >
                                    {movement.concept}
                                  </Button>
                                </div>
                                <div className='text-zinc-500'>
                                  {movement.category?.group}
                                  {' - '}
                                  {movement.category?.name ?? 'Sin categoria'}
                                </div>
                              </div>
                              <div className='text-right'>
                                {numeral(movement.amount).format('$0,0')}
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                    );
                  })}
                </div>
              </TabsContent>
            </div>
          </Tabs>
        </div>
        {showEditMovement && (
          <div className='absolute bottom-0 top-0 left-0 right-0 bg-black bg-opacity-5'>
            <div className='relative inner h-full'>
              <div className='absolute bottom-0 z-100 left-2 right-2 h-[auto] max-h-full border border-zinc-200 bg-white p-4 rounded-t'>
                <div className='mb-2 flex justify-between items-center'>
                  <div className='font-bold '>Edit movement</div>
                  <Button
                    as='link'
                    className='!text-zinc-500'
                    onClick={() => setShowEditMovement(undefined)}
                  >
                    <FontAwesomeIcon icon={faTimes} />
                  </Button>
                </div>
                <MovementForm
                  categoriesItems={categoriesItems}
                  defaultValues={showEditMovement}
                  onSaving={() => {}}
                  onSave={() => {
                    setShowEditMovement(undefined);
                    loadMovements();
                  }}
                  onError={() => {}}
                />
              </div>
            </div>
          </div>
        )}
      </div>
    </PullToRefresh>
  );
}

export default HomeModule;

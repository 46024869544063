import {GoogleLogin} from "@react-oauth/google";
import {useUser} from "contexts/UserContext";
import {setToken} from "helpers/storage";
import {useNavigate} from "react-router-dom";
import {useService} from "services/hooks/useService";

import usersService from "services/users";

function LoginModule() {
  const {setUser} = useUser();
  const navigate = useNavigate();
  const useServiceUserMe = useService<User>(
    async () => usersService.me(),
    false
  );

  return (
    <div className='h-[100vh] items-center justify-center grid bg-zinc-600 text-white'>
      <div className='text-center flex flex-col gap-8 text-sm'>
        <div>Login with Google to continue</div>
        <GoogleLogin
          onSuccess={async (credentialResponse) => {
            if (credentialResponse.credential) {
              const rsp = await usersService.loginFromGoogle(
                credentialResponse.credential
              );
              if (rsp.status) {
                setToken(rsp.data.token);
                const rspUser = await useServiceUserMe.loader();
                if (rspUser.status) {
                  setUser(rspUser.data);
                  navigate("/");
                }
              }
            }
          }}
          onError={() => {}}
        />
      </div>
    </div>
  );
}

export default LoginModule;
